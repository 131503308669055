<template>
  <!-- 页面name：运营任务 -->
  <div class="dict">
    <!--  v-if="isAdd" -->
    <!-- 表单 -->
    <div class="form-header-btn btn1">
      <div class="title">任务列表</div>
    </div>
    <div class="form-header-btn btn2">
      <div>
        <el-form
          :model="form"
          onsubmit="return false"
          :inline="true"
          class="search-form"
        >
          <el-form-item v-if="role == 1">
            <el-select
              v-model="form.companyName"
              clearable
              @change="changeCompany"
              placeholder="请选择分公司"
            >
              <el-option
                :label="item.name"
                :value="item.label"
                v-for="(item, index) in companyList"
                :key="index"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="role == 1 || role == 2">
            <el-select
              v-model="form.campusName"
              :disabled="compantIsUse"
              clearable
              placeholder="请选择园校"
            >
              <el-option
                :label="item.name"
                :value="item.label"
                v-for="(item, index) in schoolList"
                :key="index"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="form.jobId" clearable placeholder="请选择岗位">
              <el-option
                v-for="(item, index) in dictList.post_label"
                :key="index"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-input
              v-model="form.itemName"
              clearable
              placeholder="任务名称"
            />
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.name" clearable placeholder="姓名" />
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="form.startTime"
              type="daterange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="~"
              start-placeholder="开始截止日期"
              end-placeholder="结束截止日期"
              class="select-time"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="form.taskStatus"
              clearable=""
              placeholder="请选择状态"
            >
              <el-option
                v-for="(item, index) in stateOptional"
                :key="index"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="searchFun"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button
              @click="resetFun"
              class="reset"
              icon="el-icon-refresh-right"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div>
      <el-radio-group
        v-model="activeName"
        @change="changeTab"
        style="margin-bottom: 1.04167vw"
      >
        <el-radio-button label="0">全部</el-radio-button>
        <el-radio-button label="1">待办任务</el-radio-button>
        <el-radio-button label="2">已办任务</el-radio-button>
      </el-radio-group>
    </div>
    <!-- (role == 1 || role == 4)?'calc(100% - 3.125vw)':'calc(100% - 0.52083vw)' -->
    <l-table
      :columns="columns"
      :dataSource="tableData"
      :pagination="pageFlag"
      :ipagination="page"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :loading="tableLoading"
      :showXh="true"
      :height="
        role == 1 || role == 3 ? 'calc(100% - 0.52083vw)' : 'calc(100% - 0.52083vw)'
      "
      :autoHeight="false"
      :class="{ school: role == 3 }"
    >
      <el-table-column
        prop="itemName"
        label="任务名称"
        align="center"
        slot="itemName"
        min-width="160"
      ></el-table-column>
      <el-table-column
        prop="companyName"
        min-width="160"
        label="分公司"
        align="center"
        slot="companyName"
      ></el-table-column>
      <el-table-column
        prop="campusName"
        label="园校"
        align="center"
        min-width="100"
        slot="campusName"
      ></el-table-column>
      <el-table-column
        prop="jobId"
        min-width="160"
        label="岗位"
        align="center"
        slot="jobId"
      >
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.jobId"
            placement="top"
          >
            <div class="moreInfo">{{ scope.row.jobId }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="userName"
        label="姓名"
        align="center"
        slot="userName"
        min-width="160"
      >
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.userName"
            placement="top"
          >
            <div class="moreInfo">{{ scope.row.userName }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        min-width="100"
        prop="startTime"
        slot="startTime"
        label="开始时间"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{
            scope.row.startTime && scope.row.startTime.slice(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        min-width="100"
        prop="endTime"
        slot="endTime"
        label="截止时间"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.endTime && scope.row.endTime.slice(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="presentTimes"
        slot="presentTimes"
        label="次数/总次数"
        min-width="100"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.presentTimes }}/{{ scope.row.itemTimes }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="period"
        slot="period"
        label="周期(天)"
        align="center"
        min-width="80"
      >
      </el-table-column>
      <el-table-column
        prop="overdue"
        slot="overdue"
        label="逾期时间(天)"
        min-width="110"
        align="center"
      >
        <template slot-scope="scope">
          <span
            :class="
              scope.row.taskStatus.value == 1 || scope.row.taskStatus.value == 2
                ? 'activeColor'
                : ''
            "
            >{{ scope.row.overdue }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="taskStatus"
        slot="taskStatus"
        label="状态"
        min-width="110"
        align="center"
      >
        <template slot-scope="scope">
          <!-- 逾期待办红色字体  value：1 -->
          <span :class="scope.row.taskStatus.value == 1 ? 'activeColor' : ''">{{
            scope.row.taskStatus.label
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        slot="handle"
        align="center"
        fixed="right"
        min-width="250"
      >
        <template slot-scope="scope">
          <!-- v-if="isUpdate" -->
          <!-- 已完成只能查看，已废弃只能查看 -->
          <el-button
            type="text"
            @click="handleEdit(scope.row)"
            v-if="isView || isYView || isFView"
            >查看</el-button
          >
          <!-- 总部按钮权限 查看，督办，审批，办理 -->
          <div class="btnBox" v-if="role == 1 && isSupervise">
            <el-button
              v-if="
                (scope.row.taskStatus.value == 1 ||
                  scope.row.taskStatus.value == 0) &&
                scope.row.isSupervise.startsWith('0') &&
                scope.row.isCustom.value == 0 &&
                scope.row.timeBtn >= 0
              "
              @click="handleSupervise(scope.row)"
              type="text"
              >督办</el-button
            >
            <el-button
              v-if="
                (scope.row.taskStatus.value == 1 ||
                  scope.row.taskStatus.value == 0) &&
                scope.row.isSupervise.startsWith('1') &&
                scope.row.isCustom.value == 0 &&
                scope.row.timeBtn >= 0
              "
              disabled
              type="text"
              >已督办</el-button
            >
          </div>
          <!-- 总部办理的权限 -->
          <div class="btnBox" v-if="role == 1 && isDoTask">
            <el-button
              v-if="
                scope.row.taskStatus.value != 4 &&
                scope.row.isCustom.value == 1 &&
                scope.row.timeBtn >= 0
              "
              @click="handletransact(scope.row)"
              type="text"
              >办理</el-button
            >
          </div>
          <div class="btnBox" v-if="role == 1">
            <el-button
              v-if="
                scope.row.timeoutUserId &&
                scope.row.timeoutUserId.indexOf(userId) != -1 &&
                scope.row.taskStatus.value == 2 &&
                scope.row.isCustom.value == 0 &&
                scope.row.timeBtn >= 0 &&
                scope.row.timeoutUserType.value == 2
              "
              @click="handleApprove(scope.row)"
              type="text"
              >审批</el-button
            >
          </div>
          <!-- 分公司按钮权限 查看，督办，办理 -->
          <div class="btnBox" v-if="role == 2 && isFSupervise">
            <el-button
              v-if="
                (scope.row.taskStatus.value == 1 ||
                  scope.row.taskStatus.value == 0) &&
                scope.row.isSupervise.startsWith('00') &&
                scope.row.isCustom.value == 0 &&
                scope.row.timeBtn >= 0
              "
              @click="handleSupervise(scope.row)"
              type="text"
              >督办</el-button
            >
            <el-button
              v-if="
                (scope.row.taskStatus.value == 1 ||
                  scope.row.taskStatus.value == 0) &&
                !scope.row.isSupervise.startsWith('00') &&
                scope.row.isCustom.value == 0 &&
                scope.row.timeBtn >= 0
              "
              disabled
              type="text"
              >已督办</el-button
            >
          </div>
          <!-- 分公司办理的权限 -->
          <div class="btnBox" v-if="role == 2 && isFDoTask">
            <el-button
              v-if="
                scope.row.taskStatus.value == 0 &&
                scope.row.isCustom.value == 1 &&
                scope.row.timeBtn >= 0
              "
              @click="handletransact(scope.row)"
              type="text"
              >办理</el-button
            >
          </div>
          <!-- 园校 -->
          <!-- 园长 权限，查看，督办，办理，审批，逾期反馈 -->
          <!-- 园长 权限，查看，办理，逾期反馈 -->
          <div class="btnBox" v-if="role == 3 && isYDuban">
            <el-button
              v-if="
                (scope.row.taskStatus.value == 1 ||
                  scope.row.taskStatus.value == 0) &&
                scope.row.isSupervise == '000' &&
                scope.row.userId &&
                scope.row.userId.indexOf(userId) == -1 &&
                scope.row.timeBtn >= 0
              "
              @click="handleSupervise(scope.row)"
              type="text"
              >督办</el-button
            >
            <el-button
              v-if="
                (scope.row.taskStatus.value == 1 ||
                  scope.row.taskStatus.value == 0) &&
                !scope.row.isSupervise.startsWith('000') &&
                scope.row.userId &&
                scope.row.userId.indexOf(userId) == -1 &&
                scope.row.timeBtn >= 0
              "
              type="text"
              disabled
              >已督办</el-button
            >
          </div>
          <div class="btnBox" v-if="role == 3 || role == 4">
            <!-- && scope.row.timeoutUserType.value == 2  -->
            <el-button
              v-if="
                scope.row.taskStatus.value == 1 &&
                isYOverdueFeedback &&
                scope.row.userId &&
                scope.row.userId.indexOf(userId) != -1
              "
              @click="handleFeedback(scope.row)"
              type="text"
              >逾期反馈</el-button
            >
          </div>
          <!-- 园长和园校执行人合并 -->
          <div class="btnBox" v-if="role == 3 || role == 4">
            <el-button
              v-if="
                (scope.row.taskStatus.value == 0 ||
                  scope.row.taskStatus.value == 5) &&
                isYSupervise &&
                scope.row.userId &&
                scope.row.userId.indexOf(userId) != -1 &&
                scope.row.timeBtn >= 0
              "
              @click="handletransact(scope.row)"
              type="text"
              >办理</el-button
            >
          </div>
          <div class="btnBox" v-if="role == 3">
            <el-button
              v-if="
                scope.row.timeoutUserId &&
                scope.row.taskStatus.value == 2 &&
                scope.row.timeoutUserType.value == 1 &&
                isYApprove &&
                scope.row.timeoutUserId &&
                scope.row.timeoutUserId.indexOf(userId) != -1
              "
              @click="handleApprove(scope.row)"
              type="text"
              >审批</el-button
            >
          </div>
        </template>
      </el-table-column>
    </l-table>

    <!-- 督办弹窗 -->
    <el-dialog
      title="督办"
      @close="clearForm"
      :visible.sync="supervisePanel"
      width="30%"
    >
      <el-row :gutter="20">
        <el-col :span="5" style="text-align: right">督办建议</el-col>
        <el-col :span="18">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="supervise.comment"
            maxlength="100"
            rows="6"
            show-word-limit
          >
          </el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="supervisePanel = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="loadingDB"
          :disabled="loadingDB"
          @click="superviseSbmit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import LTable from "@/components/tableComponent/index.vue";
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    LTable,
  },
  data() {
    return {
      tableLoading: false, // tableloading
      page: {
        num: 1,
        size: 10,
        total: 0,
      }, // 分页信息
      pageFlag: false,
      columns: [
        {
          label: "任务名称",
          slotName: "itemName",
        },
        {
          label: "分公司",
          slotName: "companyName",
        },
        {
          label: "园校",
          slotName: "campusName",
        },
        {
          label: "岗位",
          slotName: "jobId",
        },
        {
          label: "姓名",
          slotName: "userName",
        },
        {
          label: "开始时间",
          slotName: "startTime",
        },
        {
          label: "截止时间",
          slotName: "endTime",
        },
        {
          label: "次数/总次数",
          slotName: "presentTimes",
        },
        {
          label: "周期(天)",
          slotName: "period",
        },
        {
          label: "逾期时间(天)",
          slotName: "overdue",
        },
        {
          label: "状态",
          slotName: "taskStatus",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      props: {
        multiple: true,
        value: "label",
        label: "name",
        children: "child",
      },
      form: {
        campusName: null,
        companyName: null,
        jobId: null,
        itemName: null,
        name: null,
        taskStatus: null,
        startTime: null,
        endTime: null,
      },
      tableData: [],
      permissionButtonList: [], // 权限按钮list
      isUpdate: false,
      isAdd: false,
      isDelete: false,
      activeName: "1",
      companyList: [],
      schoolList: [],
      postList: [
        // {
        //     value:1,
        //     label:'test1'
        // },
        // {
        //     value:2,
        //     label:'test2'
        // },
      ],
      dictList: {
        operate_task_status: [],
        post_label: [],
      },
      stateOptional: [],
      role: 0,
      supervisePanel: false,
      supervise: {
        comment: "",
      },
      itemID: 0,
      userId: 0,
      isView: false,
      isSupervise: false,
      isApprove: false,
      isFView: false,
      isFSupervise: false,
      isYView: false,
      isYSupervise: false,
      isYOverdueFeedback: false,
      isYApprove: false,
      isYDuban: false,
      initVariable: "",
      compantIsUse: false,
      // dictList:{
      //     operate_timeout_user_type:[],
      //     operate_is_use:[],
      //     operate_is_must:[],
      //     attachment_type:[],
      // },
      companyLabel: null,
      campusLabel: null,
      loadingDB: false,
      isDoTask: false,
      isFDoTask: false,
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          // 总部
          // if(this.$store.state.permissions.indexOf("org:z") != -1){
          this.isView = val.indexOf("z_upcomingTasks:view") != -1;
          this.isSupervise = val.indexOf("z_upcomingTasks:supervise") != -1; //督办
          this.isApprove = val.indexOf("z_upcomingTasks:approve") != -1; //审批
          this.isDoTask = val.indexOf("z_upcomingTasks:do") != -1; //审批
          // z_upcomingTasks:do
          // }
          // 分公司
          // if(this.$store.state.permissions.indexOf("org:f") != -1){
          this.isFView = val.indexOf("f_upcomingTasks:view") != -1;
          this.isFSupervise = val.indexOf("f_upcomingTasks:supervise") != -1; //督办
          this.isFDoTask = val.indexOf("f_upcomingTasks:do") != -1; //督办
          // }
          // 园校
          // if(this.$store.state.permissions.indexOf("org:f") == -1 && this.$store.state.permissions.indexOf("org:z") == -1){
          this.isYView = val.indexOf("y_upcomingTasks:view") != -1; //查看
          this.isYSupervise = val.indexOf("y_upcomingTasks:supervise") != -1; //办理
          this.isYOverdueFeedback =
            val.indexOf("y_upcomingTasks:overdueFeedback") != -1; //逾期反馈
          this.isYApprove = val.indexOf("y_upcomingTasks:approve") != -1; //审批
          this.isYDuban = val.indexOf("y_upcomingTasks:supervise") != -1; //督办
          // }
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            console.log(data, "右侧权限按钮按钮按钮按钮啊");
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
    "$route":{
      handler(val){
        if (this.$route.name == "upcomingTasksHead") {
          // 总部
          this.role = 1;
          this.initVariable = "headoffice";
          this.compantIsUse = true;
          this.getDept(0, "NODE_ORG");
          this.companyLabel = null;
          this.campusLabel = null;
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
          this.init();
        } else if (this.$route.name == "upcomingTasksBranchOffice") {
          // 分公司
          this.role = 2;
          this.initVariable = "company";
          this.getDept(
            this.$store.state.userInfo.schoolList[0].companyLabel,
            "NODE_SCHOOL"
          );
          this.companyLabel = this.$store.state.userInfo.schoolList[0].companyLabel;
          this.campusLabel = null;
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
          this.init();
        } else if (this.$route.name == "upcomingTasksPrincipal") {
          // 园校-园长
          // this.role = 3
          this.companyLabel = null;
          this.campusLabel = this.$store.state.userInfo.schoolList[0].schoolLabel;
          this.initVariable = "campus";
          // 园校-园校执行人
          this.getUserByPostLabel();
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
          this.init();
          // this.role = 4
          // /aFAwR0ZpbywrIEnyff6Iw==
        }
      },
      deep:true
    }
  },
  async created() {
    // console.log((this.$store.state.permissions))
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.userId = userInfo.userId;
    // console.log(this.$route)
    if (this.$route.name == "upcomingTasksHead") {
      // 总部
      this.role = 1;
      this.initVariable = "headoffice";
      this.compantIsUse = true;
      this.getDept(0, "NODE_ORG");
      this.companyLabel = null;
      this.campusLabel = null;
    } else if (this.$route.name == "upcomingTasksBranchOffice") {
      // 分公司
      this.role = 2;
      this.initVariable = "company";
      this.getDept(
        this.$store.state.userInfo.schoolList[0].companyLabel,
        "NODE_SCHOOL"
      );
      this.companyLabel = this.$store.state.userInfo.schoolList[0].companyLabel;
      this.campusLabel = null;
    } else if (this.$route.name == "upcomingTasksPrincipal") {
      // 园校-园长
      // this.role = 3
      this.companyLabel = null;
      this.campusLabel = this.$store.state.userInfo.schoolList[0].schoolLabel;
      this.initVariable = "campus";
      // 园校-园校执行人
      await this.getUserByPostLabel();
      // this.role = 4
      // /aFAwR0ZpbywrIEnyff6Iw==
    }
    this.queryDict();
    // this.getDept(0,'NODE_ORG');
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });

    this.init();
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    /** 初始化 */
    init() {
      this.tableLoading = true;
      let startTime = null,
        endTime = null;
      if (this.form.startTime) {
        startTime = this.form.startTime[0];
        endTime = this.form.startTime[1];
      }
      let taskStatus = null;
      if (this.form.taskStatus) {
        taskStatus = this.form.taskStatus;
      } else if (this.activeName == "0") {
        taskStatus = null;
      } else if (this.activeName == "1") {
        // taskStatus = '0,1,2'
        if (this.initVariable == "headoffice") {
          taskStatus = "2,5";
        } else if (this.initVariable == "company") {
          taskStatus = "5";
        } else if (this.initVariable == "campus") {
          if (this.role == 3) {
            taskStatus = "0,1,2,5";
          } else {
            taskStatus = "0,1,5";
          }
        }
      } else if (this.activeName == "2") {
        // taskStatus = '3,4'
        if (this.initVariable == "headoffice") {
          taskStatus = "3,4";
        } else if (this.initVariable == "company") {
          taskStatus = "4";
        } else if (this.initVariable == "campus") {
          taskStatus = "2,3,4";
        }
      }
      this.$api
        .getTaskDetailList({
          current: this.page.num,
          size: this.page.size,
          jobId: this.form.jobId,
          itemName: this.form.itemName,
          userName: this.form.name,
          taskStatus: taskStatus,
          endTimeBegin: startTime,
          endTimeEnd: endTime,
          flag: this.initVariable,
          companyLabel:
            this.initVariable == "company"
              ? this.companyLabel
              : this.form.companyName,
          campusLabel:
            this.initVariable == "campus"
              ? this.campusLabel
              : this.form.campusName,
          tabFlag: this.activeName,
        })
        .then((res) => {
          this.tableLoading = false;
          if (res.data.code == "0") {
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
            if (res.data.data.total >= 1) {
              this.pageFlag = true;
            } else {
              this.pageFlag = false;
            }
          } else {
            this.tableData = [];
            this.page.total = 0;
            this.pageFlag = false;
            this.$message.error(res.data.msg);
          }
        });
    },
    changeCompany(val) {
      if (val) {
        this.getDept(val, "NODE_SCHOOL");
        this.compantIsUse = false;
        this.form.campusName = "";
      } else {
        this.form.campusName = "";
        this.compantIsUse = true;
        this.schoolList = [];
      }
    },
    // 判断是否是园长
    async getUserByPostLabel() {
      await this.$api
        .getUserByPostLabel({
          officeLabel: this.$store.state.userInfo.schoolList[0].schoolLabel,
          label: "/aFAwR0ZpbywrIEnyff6Iw==",
        })
        .then((res) => {
          if (res.data.code == "0") {
            if (
              res.data.data &&
              res.data.data.indexOf(this.$store.state.userInfo.userId) >= 0
            ) {
              this.role = 3;
            } else {
              this.role = 4;
            }
          } else {
            this.$message.error(res.data.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    getDept(parent, type) {
      this.$api
        .getCompanyDept({
          parent: parent,
          type: type,
        })
        .then((res) => {
          if (res.data.code == "0") {
            if (type == "NODE_ORG") {
              this.companyList = res.data.data;
            } else {
              this.schoolList = res.data.data;
            }
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    initStatus() {
      if (this.activeName == 0) {
        this.stateOptional = this.dictList.operate_task_status;
      } else if (this.activeName == 1) {
        this.stateOptional = [];
        // 待办
        if (this.initVariable == "headoffice") {
          // taskStatus = '2,5'
          this.dictList.operate_task_status.map((e) => {
            if (e.value == 2 || e.value == 5) {
              this.stateOptional.push(e);
            }
          });
        } else if (this.initVariable == "company") {
          // taskStatus = '5'
          this.dictList.operate_task_status.map((e) => {
            if (e.value == 5) {
              this.stateOptional.push(e);
            }
          });
        } else if (this.initVariable == "campus") {
          if (this.role == 3) {
            // taskStatus = '0,1,2,5'
            this.dictList.operate_task_status.map((e) => {
              if (
                e.value == 0 ||
                e.value == 1 ||
                e.value == 2 ||
                e.value == 5
              ) {
                this.stateOptional.push(e);
              }
            });
          } else {
            // taskStatus = '0,1,5'
            this.dictList.operate_task_status.map((e) => {
              if (e.value == 0 || e.value == 1 || e.value == 5) {
                this.stateOptional.push(e);
              }
            });
          }
        }
      } else if (this.activeName == 2) {
        this.stateOptional = [];
        // this.dictList.operate_task_status.map(e=>{
        //     if(e.value == 3 || e.value == 4){
        //         this.stateOptional.push(e)
        //     }
        // })

        // taskStatus = '3,4'
        if (this.initVariable == "headoffice") {
          // taskStatus = '3,4'
          this.dictList.operate_task_status.map((e) => {
            if (e.value == 3 || e.value == 4) {
              this.stateOptional.push(e);
            }
          });
        } else if (this.initVariable == "company") {
          // taskStatus = '4'
          this.dictList.operate_task_status.map((e) => {
            if (e.value == 4) {
              this.stateOptional.push(e);
            }
          });
        } else if (this.initVariable == "campus") {
          // taskStatus = '2,3,4'
          this.dictList.operate_task_status.map((e) => {
            if (e.value == 2 || e.value == 3 || e.value == 4) {
              this.stateOptional.push(e);
            }
          });
        }
      }
    },
    handleSupervise(row) {
      // console.log(row)
      this.supervisePanel = true;
      this.itemID = row.id;
    },
    clearForm() {
      this.supervise.comment = "";
    },
    superviseSbmit() {
      this.loadingDB = true;
      this.$api
        .supervisionSave({
          comment: this.supervise.comment,
          taskDetailId: this.itemID,
          superviseLevel: this.role,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.supervisePanel = false;
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.init();
          } else {
            this.$message.error(res.data.msg);
          }
          this.loadingDB = false;
        })
        .catch((error) => {
          this.loadingDB = false;
        });
    },
    changeTab(val) {
      // 待办，全部，已办
      this.page.num = 1;
      this.form.taskStatus = "";
      this.initStatus();
      this.init();
    },
    queryDict() {
      var dict = Object.keys(this.dictList);
      let requests = dict.map(
        (item) =>
          new Promise((resolve, reject) => {
            this.$api
              .getDictionary({ type: item })
              .then((res) => {
                res.data.fieldName = item;
                resolve(res.data);
              })
              .catch(() => {
                resolve([]);
              });
          })
      );
      Promise.all(requests).then((res) => {
        res.forEach((item) => {
          this.dictList[item.fieldName] = item.data;
          if (item.fieldName == "operate_task_status") {
            this.initStatus();
          }
        });
      });
    },
    // 查询
    searchFun() {
      this.page.num = 1;
      this.init();
    },
    // 重置按钮
    resetFun() {
      this.form = {
        campusName: "",
        companyName: "",
        jobId: "",
        itemName: "",
        name: "",
        taskStatus: "",
        startTime: "",
        endTime: "",
      };
      this.page.num = 1;
      this.init();
    },
    /** 行内-编辑 */
    handleEdit(row, type) {
      this.$router.push({
        path: "/upcomingTasksDetail",
        query: {
          id: row.id,
          role: this.role,
          timeBtn: row.timeBtn,
        },
      });
    },
    /** 逾期反馈 */
    handleFeedback(row) {
      this.$router.push({
        path: "/upcomingTasksFeedback",
        query: {
          id: row.id,
        },
      });
    },
    /** 办理 */
    handletransact(row) {
      this.$router.push({
        path: "/upcomingTasksHandle",
        query: {
          id: row.id,
        },
      });
    },
    /** 审批 */
    handleApprove(row) {
      this.$router.push({
        path: "/upcomingTasksApprove",
        query: {
          id: row.id,
        },
      });
    },
    /** 分页 */
    handleCurrentChange(val) {
      console.log(val);
      this.page.num = val;
      this.init();
    },
    handleSizeChange(val) {
      console.log(val);
      this.page.size = val;
      this.page.num = 1;
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  height: calc(100% - 56px);
  padding: 26px 30px 30px 30px;
  .tableList {
    height: calc(100% - 250px);
  }
}
.form-header-btn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;

  .sheet-btn {
    background: #2a00b2;
    box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
    opacity: 1;
    border-radius: 4px;
    font-size: 14px;
    color: #ffffff;
  }

  /deep/.el-button + .el-button {
    margin-left: 20px;
  }
  &.btn2 {
    margin-bottom: 0px;
    .select-time {
      width: 260px;
    }
  }
  &.btn1 {
    margin-bottom: 16px;
  }
}
.title {
  font-weight: bold;
  font-size: 22px;
}
.btnBox {
  display: inline-block;
  margin-left: 4px;
}
.moreInfo {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  color: #c0c4cc !important;
}
</style>
